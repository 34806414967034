import styled from "styled-components"

import { Link, useNavigate } from "react-router-dom"

import Parse from "html-react-parser"

import { siteName, tokenCookieName } from "../../__env"

import { useDispatch, useSelector } from "react-redux"

import { Squeeze as Hamburger } from "hamburger-react"

import useWindowDimensions from "../../hooks/useWindowDimensions"

import { useState } from "react"

import { waitFor } from "../../controllers/TimeCtrl"

import { stateClass } from "../../controllers/UICtrl"
import Cookies from "universal-cookie"
import { sendMiniMessage, sendXMessage } from "../../controllers/MessageCtrl"
import { logoutUser } from "../../api"
import { postApiJson } from "../../controllers/APICtrl"
import { removeUserData } from "../../store/slice/userSlice"
import { removeCartData } from "../../store/slice/cartSlice"


const NavBar = () => {

  const navigate = useNavigate()

  const cookies = new Cookies()

  const dispatch = useDispatch()

  const { available } = useSelector((store: any) => store.user)

  const { width } = useWindowDimensions()

  const [navOpen, setNavOpen] = useState(false)

  const [navClosing, setNavClosing] = useState(false)

  const toggleNav = async (val: any) => {

    setNavClosing(false)

    // Start transition when closing
    if (val === false) setNavClosing(true)

    setNavOpen(val)

    // End transition when closing
    if (val === false) {

      await waitFor(500)

      setNavClosing(false)
    }

  }

  const closeNav = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

    const target = (e.target as HTMLDivElement).classList

    if (target.contains("r-side") && target.contains("show")) toggleNav(false)

  }

  const logoutThisUser = async (e: any) => {

    e.preventDefault()

    toggleNav(false)

    const res = await sendXMessage({

      heading: { text: "Confirm Logout" },

      buttons: [

        // @ts-ignore
        { text: 'Yes, Logout', waitFor: 'se', style: { backgroundColor: 'darkred' } },

        // @ts-ignore
        { text: 'Go Back', waitFor: 're', style: { backgroundColor: '#607d8b' } },

      ],

    })

    if (res !== "se") return false

    sendMiniMessage({

      icon: { name: "loading", style: {} },

      content: { text: "Logging Out!", style: {} },

      style: {}

    })

    const logoutData = await postApiJson(logoutUser())

    if (logoutData.error) {

      sendMiniMessage({

        icon: { name: "times", style: {} },

        content: { text: "An Error Occured!", style: {} },

        style: {}

      }, 2000)

    } else {

      sendMiniMessage({

        icon: { name: "ok" },

        content: { text: "Logged Out!" }

      }, 2000)

      cookies.remove(tokenCookieName, { path: '/' })

      dispatch(removeUserData())

      dispatch(removeCartData())

      navigate("/login")

    }

  }

  return (

    <NavBarStyle style={{ zIndex: (navOpen || navClosing) ? 80 : 30 }}>

      <nav>

        <div className="in-nav">

          <div className="header">

            <h1><Link to="/">{siteName}</Link></h1>

          </div>

          <div className={"r-side " + stateClass(navOpen, "show") + stateClass(navClosing, "show close")} onClick={closeNav}>

            {available ?

              <ul className="children">

                <li className="only-small"><Link to="/?view=query" onClick={() => toggleNav(false)}>Home</Link></li>

                <li><Link to="/signup" onClick={logoutThisUser}>Logout</Link></li>

              </ul>

              :

              <ul className="children">

                <li className="only-small"><Link to="/?view=query" onClick={() => toggleNav(false)}>Home</Link></li>

                <li><Link to="/signup" onClick={() => toggleNav(false)}>Signup</Link></li>

                <li><Link to="/login" onClick={() => toggleNav(false)}>Login</Link></li>

              </ul>

            }

            <div className="nav-ham-in">

              <Hamburger toggled={navOpen} toggle={toggleNav} size={35} distance="sm" rounded />

            </div>

          </div>

          <div className="nav-ham">

            <Hamburger toggled={navOpen} toggle={toggleNav} size={35} distance="sm" rounded />

          </div>

        </div>

      </nav>

      <div className="curvy-stuff">

        {chooseSVG(width)}

      </div>

    </NavBarStyle>

  )

}

const NavBarStyle = styled.div`
  z-index: 30;
  width: 100%;
  /* padding-bottom: 2rem; */

  nav {
    z-index: 15;
    /* background-color: #07165a; */
    color: white;

    .in-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 a {
        padding: 2rem 1.5rem;
        display: block;
        color: inherit;
        text-decoration: none;
      }

      ul.children {
        display: flex;
        list-style-type: none;
        padding-right: 1.5rem;

        li a {
          padding: .75rem 1.5rem;
          display: block;
          color: inherit;
          text-decoration: none;
        }
      }

      .nav-ham {
        padding-right: 1rem;
        display: none;
      }

      .nav-ham-in {
        display: none;
      }

      .only-small {
        display: none;
      }

      @keyframes scale-int {
        from{ transform: scale(0) }
        to{ transform: scale(1) }
      }

      @keyframes scale-int-r {
        from{ transform: scale(1) }
        to{ transform: scale(0) }
      }

      @media screen and (max-width: 800px) {

        @media screen and (max-width: 500px) {
          h1 { font-size: 1.6pc;}
        }
        
        @media screen and (max-width: 350px) {
          h1 { font-size: 1.5pc }
        }
        
        .r-side {
          display: none;

          &.show {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 0; left: 0;
            right: 0; bottom: 0;
            z-index: 150;
            background-color: rgba(0, 0, 0, .3);
            /* transform: scale(1); */
            animation: scale-int .5s 1;

            ul.children {
              min-width: 60vw;
              background-color: #eee;
              /* padding: 3rem 0; */
              border-radius: 1rem;
              flex-direction: column;
              background-color: #343d5f;
              overflow: hidden;
              padding-right: 0;

              li a {
                width: 100%;
                padding: 2rem 0;

                /* border: 2px solid white; */
                color: white;
                text-align: center;
                /* transition: background-color .5s; */

                &:hover {
                  background-color: rgba(0, 0, 0, .3);
                }
              }
            }

            &.close {
              transform: scale(0);
              animation: scale-int-r .5s 1;
            }
          }
        }

        .nav-ham {
          display: flex;
        }
        
        .nav-ham-in {
          display: flex;
          color: red;
          position: fixed;
          top: 2rem;
          right: 1rem;
          animation: opacity-in 1s 1;
        }

        .only-small {
          display: initial;
        }
      }
    }
  }

  .curvy-stuff {
    z-index: 10;
    position: absolute;
    top: 0; left: 0;
    right: 0;
    display: flex;

    svg {
      display: block;
    }
  }

`

const chooseSVG = (width: number) => {

  let desiredSVG: any = <></>

  if (width >= 1000) {

    desiredSVG = Parse(`
    <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><style>
    .path-0{
      animation:pathAnim-0 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    @keyframes pathAnim-0{
      0%{
        d: path("M 0,400 C 0,400 0,133 0,133 C 70.05128205128207,142.4153846153846 140.10256410256414,151.83076923076922 226,148 C 311.89743589743586,144.16923076923078 413.6410256410255,127.09230769230768 503,127 C 592.3589743589745,126.90769230769232 669.3333333333334,143.80000000000004 732,154 C 794.6666666666666,164.19999999999996 843.025641025641,167.70769230769227 916,171 C 988.974358974359,174.29230769230773 1086.5641025641025,177.3692307692308 1178,171 C 1269.4358974358975,164.6307692307692 1354.7179487179487,148.8153846153846 1440,133 C 1440,133 1440,400 1440,400 Z");
      }
      25%{
        d: path("M 0,400 C 0,400 0,133 0,133 C 81.1076923076923,125.63076923076923 162.2153846153846,118.26153846153845 248,122 C 333.7846153846154,125.73846153846155 424.2461538461539,140.5846153846154 509,154 C 593.7538461538461,167.4153846153846 672.8,179.39999999999998 740,182 C 807.2,184.60000000000002 862.5538461538462,177.81538461538463 943,165 C 1023.4461538461538,152.18461538461537 1128.9846153846154,133.33846153846153 1216,127 C 1303.0153846153846,120.66153846153847 1371.5076923076922,126.83076923076923 1440,133 C 1440,133 1440,400 1440,400 Z");
      }
      50%{
        d: path("M 0,400 C 0,400 0,133 0,133 C 64.30769230769232,117.07692307692308 128.61538461538464,101.15384615384616 206,106 C 283.38461538461536,110.84615384615384 373.8461538461538,136.46153846153845 472,142 C 570.1538461538462,147.53846153846155 676,133 750,127 C 824,121 866.1538461538462,123.53846153846155 936,130 C 1005.8461538461538,136.46153846153845 1103.3846153846155,146.84615384615384 1192,148 C 1280.6153846153845,149.15384615384616 1360.3076923076924,141.0769230769231 1440,133 C 1440,133 1440,400 1440,400 Z");
      }
      75%{
        d: path("M 0,400 C 0,400 0,133 0,133 C 62.07179487179488,153.11282051282052 124.14358974358976,173.225641025641 216,175 C 307.85641025641024,176.774358974359 429.49743589743593,160.21025641025642 522,147 C 614.5025641025641,133.78974358974358 677.8666666666667,123.93333333333335 743,125 C 808.1333333333333,126.06666666666665 875.0358974358974,138.05641025641023 947,149 C 1018.9641025641026,159.94358974358977 1095.9897435897437,169.84102564102562 1179,167 C 1262.0102564102563,164.15897435897438 1351.0051282051281,148.5794871794872 1440,133 C 1440,133 1440,400 1440,400 Z");
      }
      100%{
        d: path("M 0,400 C 0,400 0,133 0,133 C 70.05128205128207,142.4153846153846 140.10256410256414,151.83076923076922 226,148 C 311.89743589743586,144.16923076923078 413.6410256410255,127.09230769230768 503,127 C 592.3589743589745,126.90769230769232 669.3333333333334,143.80000000000004 732,154 C 794.6666666666666,164.19999999999996 843.025641025641,167.70769230769227 916,171 C 988.974358974359,174.29230769230773 1086.5641025641025,177.3692307692308 1178,171 C 1269.4358974358975,164.6307692307692 1354.7179487179487,148.8153846153846 1440,133 C 1440,133 1440,400 1440,400 Z");
      }
    }</style><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#1d296288"></stop><stop offset="95%" stop-color="#1d39c788"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,133 0,133 C 70.05128205128207,142.4153846153846 140.10256410256414,151.83076923076922 226,148 C 311.89743589743586,144.16923076923078 413.6410256410255,127.09230769230768 503,127 C 592.3589743589745,126.90769230769232 669.3333333333334,143.80000000000004 732,154 C 794.6666666666666,164.19999999999996 843.025641025641,167.70769230769227 916,171 C 988.974358974359,174.29230769230773 1086.5641025641025,177.3692307692308 1178,171 C 1269.4358974358975,164.6307692307692 1354.7179487179487,148.8153846153846 1440,133 C 1440,133 1440,400 1440,400 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)"></path><style>
    .path-1{
      animation:pathAnim-1 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    @keyframes pathAnim-1{
      0%{
        d: path("M 0,400 C 0,400 0,266 0,266 C 77.46153846153848,235.8128205128205 154.92307692307696,205.62564102564102 246,223 C 337.07692307692304,240.37435897435898 441.7692307692307,305.31025641025644 517,310 C 592.2307692307693,314.68974358974356 638,259.1333333333333 719,236 C 800,212.86666666666667 916.2307692307693,222.15641025641023 1003,226 C 1089.7692307692307,229.84358974358977 1147.076923076923,228.24102564102566 1215,234 C 1282.923076923077,239.75897435897434 1361.4615384615386,252.87948717948717 1440,266 C 1440,266 1440,400 1440,400 Z");
      }
      25%{
        d: path("M 0,400 C 0,400 0,266 0,266 C 95.83333333333331,274.4794871794872 191.66666666666663,282.9589743589744 262,289 C 332.33333333333337,295.0410256410256 377.1666666666667,298.6435897435897 444,290 C 510.8333333333333,281.3564102564103 599.6666666666665,260.4666666666667 685,254 C 770.3333333333335,247.5333333333333 852.166666666667,255.48974358974357 941,262 C 1029.833333333333,268.51025641025643 1125.6666666666665,273.574358974359 1210,274 C 1294.3333333333335,274.425641025641 1367.1666666666667,270.2128205128205 1440,266 C 1440,266 1440,400 1440,400 Z");
      }
      50%{
        d: path("M 0,400 C 0,400 0,266 0,266 C 73.26666666666668,259.0076923076923 146.53333333333336,252.01538461538462 225,248 C 303.46666666666664,243.98461538461538 387.1333333333333,242.9461538461538 476,238 C 564.8666666666667,233.0538461538462 658.9333333333333,224.20000000000002 731,218 C 803.0666666666667,211.79999999999998 853.1333333333334,208.25384615384615 919,230 C 984.8666666666666,251.74615384615385 1066.5333333333333,298.7846153846154 1156,309 C 1245.4666666666667,319.2153846153846 1342.7333333333333,292.60769230769233 1440,266 C 1440,266 1440,400 1440,400 Z");
      }
      75%{
        d: path("M 0,400 C 0,400 0,266 0,266 C 79.42051282051281,245.45128205128205 158.84102564102562,224.9025641025641 227,222 C 295.1589743589744,219.0974358974359 352.05641025641023,233.84102564102562 444,250 C 535.9435897435898,266.1589743589744 662.9333333333334,283.7333333333333 741,276 C 819.0666666666666,268.2666666666667 848.2102564102565,235.22564102564107 927,242 C 1005.7897435897435,248.77435897435893 1134.225641025641,295.36410256410255 1228,306 C 1321.774358974359,316.63589743589745 1380.8871794871795,291.31794871794875 1440,266 C 1440,266 1440,400 1440,400 Z");
      }
      100%{
        d: path("M 0,400 C 0,400 0,266 0,266 C 77.46153846153848,235.8128205128205 154.92307692307696,205.62564102564102 246,223 C 337.07692307692304,240.37435897435898 441.7692307692307,305.31025641025644 517,310 C 592.2307692307693,314.68974358974356 638,259.1333333333333 719,236 C 800,212.86666666666667 916.2307692307693,222.15641025641023 1003,226 C 1089.7692307692307,229.84358974358977 1147.076923076923,228.24102564102566 1215,234 C 1282.923076923077,239.75897435897434 1361.4615384615386,252.87948717948717 1440,266 C 1440,266 1440,400 1440,400 Z");
      }
    }</style><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#1d2962ff"></stop><stop offset="95%" stop-color="#1d39c7ff"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,266 0,266 C 77.46153846153848,235.8128205128205 154.92307692307696,205.62564102564102 246,223 C 337.07692307692304,240.37435897435898 441.7692307692307,305.31025641025644 517,310 C 592.2307692307693,314.68974358974356 638,259.1333333333333 719,236 C 800,212.86666666666667 916.2307692307693,222.15641025641023 1003,226 C 1089.7692307692307,229.84358974358977 1147.076923076923,228.24102564102566 1215,234 C 1282.923076923077,239.75897435897434 1361.4615384615386,252.87948717948717 1440,266 C 1440,266 1440,400 1440,400 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 200)"></path></svg>
    `)

  } else if (width < 1000 && width >= 700) {

    desiredSVG = Parse(`
    <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 500" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><style>
    .path-0{
      animation:pathAnim-0 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    @keyframes pathAnim-0{
      0%{
        d: path("M 0,500 C 0,500 0,166 0,166 C 77.99230769230766,180.9794871794872 155.98461538461532,195.95897435897436 228,203 C 300.0153846153847,210.04102564102564 366.05384615384617,209.14358974358973 462,189 C 557.9461538461538,168.85641025641027 683.8,129.46666666666664 763,124 C 842.2,118.53333333333335 874.7461538461538,146.98974358974357 933,147 C 991.2538461538462,147.01025641025643 1075.2153846153847,118.57435897435897 1164,117 C 1252.7846153846153,115.42564102564103 1346.3923076923077,140.7128205128205 1440,166 C 1440,166 1440,500 1440,500 Z");
      }
      25%{
        d: path("M 0,500 C 0,500 0,166 0,166 C 68.2794871794872,140.95128205128205 136.5589743589744,115.9025641025641 215,138 C 293.4410256410256,160.0974358974359 382.0435897435897,229.34102564102565 463,229 C 543.9564102564103,228.65897435897435 617.2666666666667,158.73333333333335 691,124 C 764.7333333333333,89.26666666666667 838.8897435897436,89.72564102564102 934,106 C 1029.1102564102564,122.27435897435898 1145.174358974359,154.36410256410258 1233,167 C 1320.825641025641,179.63589743589742 1380.4128205128204,172.8179487179487 1440,166 C 1440,166 1440,500 1440,500 Z");
      }
      50%{
        d: path("M 0,500 C 0,500 0,166 0,166 C 54.28461538461539,179.05384615384617 108.56923076923078,192.1076923076923 205,209 C 301.4307692307692,225.8923076923077 440.0076923076922,246.62307692307695 520,221 C 599.9923076923078,195.37692307692305 621.4,123.39999999999999 693,112 C 764.6,100.60000000000001 886.3923076923077,149.77692307692308 970,166 C 1053.6076923076923,182.22307692307692 1099.0307692307692,165.4923076923077 1171,160 C 1242.9692307692308,154.5076923076923 1341.4846153846154,160.25384615384615 1440,166 C 1440,166 1440,500 1440,500 Z");
      }
      75%{
        d: path("M 0,500 C 0,500 0,166 0,166 C 76.73333333333332,150.08717948717947 153.46666666666664,134.17435897435897 225,140 C 296.53333333333336,145.82564102564103 362.8666666666667,173.3897435897436 437,187 C 511.1333333333333,200.6102564102564 593.0666666666667,200.26666666666668 688,202 C 782.9333333333333,203.73333333333332 890.8666666666668,207.54358974358973 986,208 C 1081.1333333333332,208.45641025641027 1163.4666666666667,205.55897435897438 1237,198 C 1310.5333333333333,190.44102564102562 1375.2666666666667,178.2205128205128 1440,166 C 1440,166 1440,500 1440,500 Z");
      }
      100%{
        d: path("M 0,500 C 0,500 0,166 0,166 C 77.99230769230766,180.9794871794872 155.98461538461532,195.95897435897436 228,203 C 300.0153846153847,210.04102564102564 366.05384615384617,209.14358974358973 462,189 C 557.9461538461538,168.85641025641027 683.8,129.46666666666664 763,124 C 842.2,118.53333333333335 874.7461538461538,146.98974358974357 933,147 C 991.2538461538462,147.01025641025643 1075.2153846153847,118.57435897435897 1164,117 C 1252.7846153846153,115.42564102564103 1346.3923076923077,140.7128205128205 1440,166 C 1440,166 1440,500 1440,500 Z");
      }
    }</style><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#1d296288"></stop><stop offset="95%" stop-color="#1d39c788"></stop></linearGradient></defs><path d="M 0,500 C 0,500 0,166 0,166 C 77.99230769230766,180.9794871794872 155.98461538461532,195.95897435897436 228,203 C 300.0153846153847,210.04102564102564 366.05384615384617,209.14358974358973 462,189 C 557.9461538461538,168.85641025641027 683.8,129.46666666666664 763,124 C 842.2,118.53333333333335 874.7461538461538,146.98974358974357 933,147 C 991.2538461538462,147.01025641025643 1075.2153846153847,118.57435897435897 1164,117 C 1252.7846153846153,115.42564102564103 1346.3923076923077,140.7128205128205 1440,166 C 1440,166 1440,500 1440,500 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 250)"></path><style>
    .path-1{
      animation:pathAnim-1 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    @keyframes pathAnim-1{
      0%{
        d: path("M 0,500 C 0,500 0,333 0,333 C 80.2076923076923,315.91282051282053 160.4153846153846,298.825641025641 231,307 C 301.5846153846154,315.174358974359 362.54615384615386,348.6102564102564 442,369 C 521.4538461538461,389.3897435897436 619.4000000000001,396.73333333333335 719,379 C 818.5999999999999,361.26666666666665 919.8538461538462,318.45641025641027 1001,307 C 1082.1461538461538,295.54358974358973 1143.1846153846152,315.44102564102565 1213,325 C 1282.8153846153848,334.55897435897435 1361.4076923076923,333.7794871794872 1440,333 C 1440,333 1440,500 1440,500 Z");
      }
      25%{
        d: path("M 0,500 C 0,500 0,333 0,333 C 103.20000000000002,343.47435897435895 206.40000000000003,353.9487179487179 270,343 C 333.59999999999997,332.0512820512821 357.6,299.6794871794872 439,310 C 520.4,320.3205128205128 659.1999999999998,373.3333333333333 763,389 C 866.8000000000002,404.6666666666667 935.6000000000001,382.98717948717945 994,362 C 1052.3999999999999,341.01282051282055 1100.3999999999999,320.71794871794873 1173,316 C 1245.6000000000001,311.28205128205127 1342.8000000000002,322.14102564102564 1440,333 C 1440,333 1440,500 1440,500 Z");
      }
      50%{
        d: path("M 0,500 C 0,500 0,333 0,333 C 75.35897435897434,328.07435897435903 150.71794871794867,323.148717948718 236,328 C 321.2820512820513,332.851282051282 416.48717948717956,347.47948717948714 486,330 C 555.5128205128204,312.52051282051286 599.3333333333333,262.93333333333334 677,280 C 754.6666666666667,297.06666666666666 866.1794871794873,380.78717948717946 960,383 C 1053.8205128205127,385.21282051282054 1129.948717948718,305.9179487179487 1207,284 C 1284.051282051282,262.0820512820513 1362.025641025641,297.5410256410256 1440,333 C 1440,333 1440,500 1440,500 Z");
      }
      75%{
        d: path("M 0,500 C 0,500 0,333 0,333 C 97.1076923076923,327.6948717948718 194.2153846153846,322.38974358974355 269,335 C 343.7846153846154,347.61025641025645 396.2461538461539,378.13589743589745 460,377 C 523.7538461538461,375.86410256410255 598.8,343.0666666666667 696,318 C 793.2,292.9333333333333 912.553846153846,275.5974358974359 996,276 C 1079.446153846154,276.4025641025641 1126.9846153846154,294.54358974358973 1195,307 C 1263.0153846153846,319.45641025641027 1351.5076923076922,326.2282051282051 1440,333 C 1440,333 1440,500 1440,500 Z");
      }
      100%{
        d: path("M 0,500 C 0,500 0,333 0,333 C 80.2076923076923,315.91282051282053 160.4153846153846,298.825641025641 231,307 C 301.5846153846154,315.174358974359 362.54615384615386,348.6102564102564 442,369 C 521.4538461538461,389.3897435897436 619.4000000000001,396.73333333333335 719,379 C 818.5999999999999,361.26666666666665 919.8538461538462,318.45641025641027 1001,307 C 1082.1461538461538,295.54358974358973 1143.1846153846152,315.44102564102565 1213,325 C 1282.8153846153848,334.55897435897435 1361.4076923076923,333.7794871794872 1440,333 C 1440,333 1440,500 1440,500 Z");
      }
    }</style><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#1d2962ff"></stop><stop offset="95%" stop-color="#1d39c7ff"></stop></linearGradient></defs><path d="M 0,500 C 0,500 0,333 0,333 C 80.2076923076923,315.91282051282053 160.4153846153846,298.825641025641 231,307 C 301.5846153846154,315.174358974359 362.54615384615386,348.6102564102564 442,369 C 521.4538461538461,389.3897435897436 619.4000000000001,396.73333333333335 719,379 C 818.5999999999999,361.26666666666665 919.8538461538462,318.45641025641027 1001,307 C 1082.1461538461538,295.54358974358973 1143.1846153846152,315.44102564102565 1213,325 C 1282.8153846153848,334.55897435897435 1361.4076923076923,333.7794871794872 1440,333 C 1440,333 1440,500 1440,500 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 250)"></path></svg>
    `)

  } else if (width < 700 && width >= 500) {

    desiredSVG = Parse(`
    <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><style>
    .path-0{
      animation:pathAnim-0 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    @keyframes pathAnim-0{
      0%{
        d: path("M 0,600 C 0,600 0,200 0,200 C 70.86153846153846,150.0897435897436 141.72307692307692,100.17948717948715 218,127 C 294.2769230769231,153.82051282051285 375.96923076923076,257.3717948717949 465,271 C 554.0307692307692,284.6282051282051 650.4000000000001,208.33333333333331 741,168 C 831.5999999999999,127.66666666666667 916.4307692307693,123.2948717948718 985,153 C 1053.5692307692307,182.7051282051282 1105.876923076923,246.4871794871795 1179,260 C 1252.123076923077,273.5128205128205 1346.0615384615385,236.75641025641025 1440,200 C 1440,200 1440,600 1440,600 Z");
      }
      25%{
        d: path("M 0,600 C 0,600 0,200 0,200 C 75.05897435897435,181.41794871794872 150.1179487179487,162.83589743589744 237,151 C 323.8820512820513,139.16410256410256 422.58717948717947,134.07435897435897 504,137 C 585.4128205128205,139.92564102564103 649.5333333333334,150.86666666666665 713,157 C 776.4666666666666,163.13333333333335 839.2794871794873,164.4589743589744 931,189 C 1022.7205128205127,213.5410256410256 1143.3487179487179,261.2974358974359 1233,267 C 1322.6512820512821,272.7025641025641 1381.325641025641,236.35128205128206 1440,200 C 1440,200 1440,600 1440,600 Z");
      }
      50%{
        d: path("M 0,600 C 0,600 0,200 0,200 C 76.05384615384614,176.04871794871795 152.10769230769228,152.0974358974359 231,155 C 309.8923076923077,157.9025641025641 391.62307692307695,187.65897435897438 473,215 C 554.376923076923,242.34102564102562 635.4,267.26666666666665 719,254 C 802.6,240.73333333333332 888.7769230769231,189.27435897435896 979,161 C 1069.2230769230769,132.72564102564104 1163.4923076923078,127.63589743589745 1241,138 C 1318.5076923076922,148.36410256410255 1379.253846153846,174.18205128205128 1440,200 C 1440,200 1440,600 1440,600 Z");
      }
      75%{
        d: path("M 0,600 C 0,600 0,200 0,200 C 79.73076923076923,166.62564102564102 159.46153846153845,133.25128205128206 237,148 C 314.53846153846155,162.74871794871794 389.8846153846155,225.62051282051277 466,249 C 542.1153846153845,272.3794871794872 619,256.26666666666665 695,243 C 771,229.73333333333332 846.1153846153845,219.31282051282048 923,223 C 999.8846153846155,226.68717948717952 1078.5384615384614,244.4820512820513 1165,243 C 1251.4615384615386,241.5179487179487 1345.7307692307693,220.75897435897434 1440,200 C 1440,200 1440,600 1440,600 Z");
      }
      100%{
        d: path("M 0,600 C 0,600 0,200 0,200 C 70.86153846153846,150.0897435897436 141.72307692307692,100.17948717948715 218,127 C 294.2769230769231,153.82051282051285 375.96923076923076,257.3717948717949 465,271 C 554.0307692307692,284.6282051282051 650.4000000000001,208.33333333333331 741,168 C 831.5999999999999,127.66666666666667 916.4307692307693,123.2948717948718 985,153 C 1053.5692307692307,182.7051282051282 1105.876923076923,246.4871794871795 1179,260 C 1252.123076923077,273.5128205128205 1346.0615384615385,236.75641025641025 1440,200 C 1440,200 1440,600 1440,600 Z");
      }
    }</style><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#1d296288"></stop><stop offset="95%" stop-color="#1d39c788"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,200 0,200 C 70.86153846153846,150.0897435897436 141.72307692307692,100.17948717948715 218,127 C 294.2769230769231,153.82051282051285 375.96923076923076,257.3717948717949 465,271 C 554.0307692307692,284.6282051282051 650.4000000000001,208.33333333333331 741,168 C 831.5999999999999,127.66666666666667 916.4307692307693,123.2948717948718 985,153 C 1053.5692307692307,182.7051282051282 1105.876923076923,246.4871794871795 1179,260 C 1252.123076923077,273.5128205128205 1346.0615384615385,236.75641025641025 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 300)"></path><style>
    .path-1{
      animation:pathAnim-1 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    @keyframes pathAnim-1{
      0%{
        d: path("M 0,600 C 0,600 0,400 0,400 C 67.76153846153846,417.7153846153846 135.52307692307693,435.4307692307692 212,447 C 288.4769230769231,458.5692307692308 373.6692307692307,463.99230769230775 447,436 C 520.3307692307693,408.00769230769225 581.8000000000001,346.59999999999997 682,327 C 782.1999999999999,307.40000000000003 921.1307692307691,329.60769230769233 1001,346 C 1080.8692307692309,362.39230769230767 1101.676923076923,372.96923076923076 1165,381 C 1228.323076923077,389.03076923076924 1334.1615384615384,394.5153846153846 1440,400 C 1440,400 1440,600 1440,600 Z");
      }
      25%{
        d: path("M 0,600 C 0,600 0,400 0,400 C 66.40512820512822,428.4076923076923 132.81025641025644,456.8153846153846 214,466 C 295.18974358974356,475.1846153846154 391.16410256410256,465.14615384615394 472,436 C 552.8358974358974,406.85384615384606 618.5333333333334,358.6 699,345 C 779.4666666666666,331.4 874.7025641025641,352.45384615384614 966,361 C 1057.297435897436,369.54615384615386 1144.6564102564103,365.5846153846154 1223,370 C 1301.3435897435897,374.4153846153846 1370.6717948717949,387.2076923076923 1440,400 C 1440,400 1440,600 1440,600 Z");
      }
      50%{
        d: path("M 0,600 C 0,600 0,400 0,400 C 95.84615384615384,373.0282051282052 191.69230769230768,346.0564102564103 266,360 C 340.3076923076923,373.9435897435897 393.0769230769231,428.802564102564 466,444 C 538.9230769230769,459.197435897436 631.9999999999999,434.73333333333335 710,420 C 788.0000000000001,405.26666666666665 850.9230769230769,400.2641025641026 933,395 C 1015.0769230769231,389.7358974358974 1116.3076923076922,384.2102564102564 1204,385 C 1291.6923076923078,385.7897435897436 1365.8461538461538,392.8948717948718 1440,400 C 1440,400 1440,600 1440,600 Z");
      }
      75%{
        d: path("M 0,600 C 0,600 0,400 0,400 C 68.95384615384617,384.6102564102564 137.90769230769234,369.22051282051285 214,388 C 290.09230769230766,406.77948717948715 373.3230769230769,459.7282051282051 463,468 C 552.6769230769231,476.2717948717949 648.8,439.8666666666666 733,443 C 817.2,446.1333333333334 889.4769230769232,488.80512820512826 968,466 C 1046.5230769230768,443.19487179487174 1131.2923076923075,354.91282051282053 1211,333 C 1290.7076923076925,311.08717948717947 1365.3538461538462,355.54358974358973 1440,400 C 1440,400 1440,600 1440,600 Z");
      }
      100%{
        d: path("M 0,600 C 0,600 0,400 0,400 C 67.76153846153846,417.7153846153846 135.52307692307693,435.4307692307692 212,447 C 288.4769230769231,458.5692307692308 373.6692307692307,463.99230769230775 447,436 C 520.3307692307693,408.00769230769225 581.8000000000001,346.59999999999997 682,327 C 782.1999999999999,307.40000000000003 921.1307692307691,329.60769230769233 1001,346 C 1080.8692307692309,362.39230769230767 1101.676923076923,372.96923076923076 1165,381 C 1228.323076923077,389.03076923076924 1334.1615384615384,394.5153846153846 1440,400 C 1440,400 1440,600 1440,600 Z");
      }
    }</style><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#1d2962ff"></stop><stop offset="95%" stop-color="#1d39c7ff"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,400 0,400 C 67.76153846153846,417.7153846153846 135.52307692307693,435.4307692307692 212,447 C 288.4769230769231,458.5692307692308 373.6692307692307,463.99230769230775 447,436 C 520.3307692307693,408.00769230769225 581.8000000000001,346.59999999999997 682,327 C 782.1999999999999,307.40000000000003 921.1307692307691,329.60769230769233 1001,346 C 1080.8692307692309,362.39230769230767 1101.676923076923,372.96923076923076 1165,381 C 1228.323076923077,389.03076923076924 1334.1615384615384,394.5153846153846 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 300)"></path></svg>
    `)

  } else if (width < 500) {

    desiredSVG = Parse(`
    <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><style>
    .path-0{
      animation:pathAnim-0 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    @keyframes pathAnim-0{
      0%{
        d: path("M 0,700 C 0,700 0,233 0,233 C 75.34358974358975,210.75641025641028 150.6871794871795,188.51282051282053 232,170 C 313.3128205128205,151.48717948717947 400.5948717948718,136.7051282051282 481,149 C 561.4051282051282,161.2948717948718 634.9333333333333,200.66666666666669 716,210 C 797.0666666666667,219.33333333333331 885.6717948717949,198.62820512820514 969,217 C 1052.3282051282051,235.37179487179486 1130.3794871794873,292.8205128205128 1208,302 C 1285.6205128205127,311.1794871794872 1362.8102564102564,272.0897435897436 1440,233 C 1440,233 1440,700 1440,700 Z");
      }
      25%{
        d: path("M 0,700 C 0,700 0,233 0,233 C 107.86153846153846,203.41282051282053 215.72307692307692,173.82564102564103 279,201 C 342.2769230769231,228.17435897435897 360.96923076923076,312.1102564102564 438,294 C 515.0307692307692,275.8897435897436 650.4,155.73333333333335 756,148 C 861.6,140.26666666666665 937.4307692307693,244.95641025641027 993,287 C 1048.5692307692307,329.04358974358973 1083.876923076923,308.44102564102565 1155,289 C 1226.123076923077,269.55897435897435 1333.0615384615385,251.27948717948718 1440,233 C 1440,233 1440,700 1440,700 Z");
      }
      50%{
        d: path("M 0,700 C 0,700 0,233 0,233 C 67.14102564102566,259.60769230769233 134.28205128205133,286.2153846153846 222,274 C 309.7179487179487,261.7846153846154 418.01282051282044,210.74615384615387 500,184 C 581.9871794871796,157.25384615384613 637.6666666666667,154.8 716,181 C 794.3333333333333,207.2 895.3205128205127,262.0538461538461 973,273 C 1050.6794871794873,283.9461538461539 1105.051282051282,250.9846153846154 1179,237 C 1252.948717948718,223.0153846153846 1346.474358974359,228.0076923076923 1440,233 C 1440,233 1440,700 1440,700 Z");
      }
      75%{
        d: path("M 0,700 C 0,700 0,233 0,233 C 86.5974358974359,202.05128205128204 173.1948717948718,171.10256410256412 257,155 C 340.8051282051282,138.89743589743588 421.81794871794875,137.6410256410256 506,150 C 590.1820512820512,162.3589743589744 677.5333333333334,188.33333333333334 756,187 C 834.4666666666666,185.66666666666666 904.0487179487181,157.02564102564102 966,184 C 1027.9512820512819,210.97435897435898 1082.2717948717948,293.5641025641026 1160,311 C 1237.7282051282052,328.4358974358974 1338.8641025641027,280.71794871794873 1440,233 C 1440,233 1440,700 1440,700 Z");
      }
      100%{
        d: path("M 0,700 C 0,700 0,233 0,233 C 75.34358974358975,210.75641025641028 150.6871794871795,188.51282051282053 232,170 C 313.3128205128205,151.48717948717947 400.5948717948718,136.7051282051282 481,149 C 561.4051282051282,161.2948717948718 634.9333333333333,200.66666666666669 716,210 C 797.0666666666667,219.33333333333331 885.6717948717949,198.62820512820514 969,217 C 1052.3282051282051,235.37179487179486 1130.3794871794873,292.8205128205128 1208,302 C 1285.6205128205127,311.1794871794872 1362.8102564102564,272.0897435897436 1440,233 C 1440,233 1440,700 1440,700 Z");
      }
    }</style><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#1d296288"></stop><stop offset="95%" stop-color="#1d39c788"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,233 0,233 C 75.34358974358975,210.75641025641028 150.6871794871795,188.51282051282053 232,170 C 313.3128205128205,151.48717948717947 400.5948717948718,136.7051282051282 481,149 C 561.4051282051282,161.2948717948718 634.9333333333333,200.66666666666669 716,210 C 797.0666666666667,219.33333333333331 885.6717948717949,198.62820512820514 969,217 C 1052.3282051282051,235.37179487179486 1130.3794871794873,292.8205128205128 1208,302 C 1285.6205128205127,311.1794871794872 1362.8102564102564,272.0897435897436 1440,233 C 1440,233 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 350)"></path><style>
    .path-1{
      animation:pathAnim-1 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    @keyframes pathAnim-1{
      0%{
        d: path("M 0,700 C 0,700 0,466 0,466 C 102.41282051282047,458.48717948717945 204.82564102564095,450.97435897435895 276,475 C 347.17435897435905,499.02564102564105 387.1102564102565,554.5897435897436 468,537 C 548.8897435897435,519.4102564102564 670.7333333333332,428.66666666666663 756,403 C 841.2666666666668,377.33333333333337 889.9564102564103,416.7435897435898 953,427 C 1016.0435897435897,437.2564102564102 1093.4410256410256,418.3589743589743 1177,420 C 1260.5589743589744,421.6410256410257 1350.2794871794872,443.8205128205128 1440,466 C 1440,466 1440,700 1440,700 Z");
      }
      25%{
        d: path("M 0,700 C 0,700 0,466 0,466 C 66.16923076923078,435.0333333333333 132.33846153846156,404.06666666666666 217,397 C 301.66153846153844,389.93333333333334 404.8153846153846,406.7666666666666 487,440 C 569.1846153846154,473.2333333333334 630.4,522.8666666666667 699,508 C 767.6,493.1333333333333 843.5846153846155,413.7666666666667 934,389 C 1024.4153846153845,364.2333333333333 1129.2615384615385,394.06666666666666 1216,416 C 1302.7384615384615,437.93333333333334 1371.3692307692309,451.9666666666667 1440,466 C 1440,466 1440,700 1440,700 Z");
      }
      50%{
        d: path("M 0,700 C 0,700 0,466 0,466 C 87.98717948717947,415.7846153846154 175.97435897435895,365.5692307692308 260,392 C 344.02564102564105,418.4307692307692 424.08974358974365,521.5076923076923 495,539 C 565.9102564102564,556.4923076923077 627.6666666666667,488.40000000000003 713,471 C 798.3333333333333,453.59999999999997 907.2435897435896,486.8923076923077 983,477 C 1058.7564102564104,467.1076923076923 1101.3589743589744,414.0307692307692 1172,405 C 1242.6410256410256,395.9692307692308 1341.3205128205127,430.9846153846154 1440,466 C 1440,466 1440,700 1440,700 Z");
      }
      75%{
        d: path("M 0,700 C 0,700 0,466 0,466 C 65.77692307692308,470.83589743589744 131.55384615384617,475.6717948717948 208,477 C 284.44615384615383,478.3282051282052 371.56153846153836,476.148717948718 470,447 C 568.4384615384616,417.851282051282 678.2000000000002,361.7333333333333 764,380 C 849.7999999999998,398.2666666666667 911.6384615384613,490.9179487179488 969,532 C 1026.3615384615387,573.0820512820512 1079.246153846154,562.5948717948718 1157,543 C 1234.753846153846,523.4051282051282 1337.376923076923,494.7025641025641 1440,466 C 1440,466 1440,700 1440,700 Z");
      }
      100%{
        d: path("M 0,700 C 0,700 0,466 0,466 C 102.41282051282047,458.48717948717945 204.82564102564095,450.97435897435895 276,475 C 347.17435897435905,499.02564102564105 387.1102564102565,554.5897435897436 468,537 C 548.8897435897435,519.4102564102564 670.7333333333332,428.66666666666663 756,403 C 841.2666666666668,377.33333333333337 889.9564102564103,416.7435897435898 953,427 C 1016.0435897435897,437.2564102564102 1093.4410256410256,418.3589743589743 1177,420 C 1260.5589743589744,421.6410256410257 1350.2794871794872,443.8205128205128 1440,466 C 1440,466 1440,700 1440,700 Z");
      }
    }</style><defs><linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#1d2962ff"></stop><stop offset="95%" stop-color="#1d39c7ff"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,466 0,466 C 102.41282051282047,458.48717948717945 204.82564102564095,450.97435897435895 276,475 C 347.17435897435905,499.02564102564105 387.1102564102565,554.5897435897436 468,537 C 548.8897435897435,519.4102564102564 670.7333333333332,428.66666666666663 756,403 C 841.2666666666668,377.33333333333337 889.9564102564103,416.7435897435898 953,427 C 1016.0435897435897,437.2564102564102 1093.4410256410256,418.3589743589743 1177,420 C 1260.5589743589744,421.6410256410257 1350.2794871794872,443.8205128205128 1440,466 C 1440,466 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 350)"></path></svg>
    `)

  }

  return desiredSVG

}

export default NavBar